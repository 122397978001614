import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const OpenCloseIcon = ({ active, className, ...props }) => {
  const openPath =
    'M28.3798 37.6801V35.9721H34.6238L35.6878 36.1961L35.8278 35.8881L34.9598 35.2161L24.7678 25.0241L26.0558 23.7641L36.2198 33.9281L36.9198 34.7961L37.2278 34.6561L37.0038 33.5921V27.3201H38.7678V36.7561L37.8438 37.6801H28.3798Z'
  const closePath =
    'M25.0758 38.6802L30.7038 31.7642L25.2718 25.0442H27.5958L31.9078 30.5042H32.2438L36.5558 25.0442H38.8798L33.4478 31.7642L39.0758 38.6802H36.6958L32.2438 33.0242H31.9078L27.4558 38.6802H25.0758Z'

  return (
    <svg
      viewBox='0 0 64 64'
      className={clsx('group', className)}
      {...props}
    >
      <circle
        cx='32'
        cy='32'
        r='31'
        stroke='#01DD93'
        strokeWidth='2'
        fill={active ? '#01DD93' : 'currentColor'}
        className={`transition duration-500 text-transparent group-hover:text-brand-primary`}
      />
      <path
        className='transition duration-500 text-brand-primary group-hover:text-black'
        fill={active ? '#000000' : 'currentColor'}
        d={active ? closePath : openPath}
      />
    </svg>
  )
}

export default OpenCloseIcon

OpenCloseIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string
}

OpenCloseIcon.defaultProps = {
  active: false,
  className: ''
}
