import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import ColorsFilter from '../ColorsFilter'
import PropTypes from 'prop-types'

const Testimonial = ({ testimonial }) => {
  const { clientReview, clientAvatar, clientName, clientPosition } = testimonial
  return (
    <div className='my-0 h-full flex flex-col justify-between text-lg leading-6 font-normal lg:text-xl lg:leading-7'>
      <p className='before:content-["\""] after:content-["\""] mb-12'>
        {clientReview}
      </p>
      <div className='flex items-end'>
        <div className='h-full flex items-center'>
          <ColorsFilter className='inline-block mr-5 rounded-full z-0'>
            <GatsbyImage
              className='rounded-full w-20 h-20'
              image={clientAvatar?.childImageSharp?.gatsbyImageData}
              alt={`${clientName} avatar`}
              loading='lazy'
              imgClassName='rounded-full w-20'
            />
          </ColorsFilter>
          <div>
            <p>{clientName}</p>
            <p className='text-brand-primary'>{clientPosition}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial

Testimonial.propTypes = {
  testimonial: PropTypes.shape({
    clientReview: PropTypes.string.isRequired,
    clientAvatar: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object.isRequired
      }).isRequired
    }),
    clientName: PropTypes.string.isRequired,
    clientPosition: PropTypes.string.isRequired
  }).isRequired
}
