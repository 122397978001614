import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const arrowClassNames = (reversed) =>
  clsx(
    'absolute top-0 bottom-0 w-1/2 bg-transparent z-10 cursor-none',
    reversed ? 'left-0 prevBtn' : 'right-0 nextBtn'
  )

const SliderNavButton = ({ reversed, onClick }) => {
  const handleKeyDown = (ev) => {
    if (ev.key === 'Enter') {
      onClick()
    }
  }

  return (
    <div
      onClick={onClick}
      className={arrowClassNames(reversed)}
      onKeyDown={handleKeyDown}
      role='button'
      aria-label={reversed ? 'previous' : 'next'}
      tabIndex={0}
    />
  )
}

export default SliderNavButton

SliderNavButton.propTypes = {
  reversed: PropTypes.bool,
  onClick: PropTypes.func
}

SliderNavButton.defaultProps = {
  reversed: false
}
